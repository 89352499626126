import React from "react";
import "./Resources.css";
import { LanguageContext } from "../../Helper/Context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const LouisaBiog = () => {
  const { lang, setLang } = useContext(LanguageContext);
  document.body.style = "background: #f2f2f2;";
  const navigate = useNavigate();

  return (
    <>
      <div className="resourcePage">
        <h1 className="resourceH1">
          {lang
            ? "Hunangofiant Dychmygol Louisa BB Morgan"
            : "An imaginary biography of Louisa BB Morgan"}
        </h1>
        <p className="resourceP">
          {lang
            ? "Mae teithio yn ehangu gorwelion, yn llythrennol. Mae teithio’n ehangu’r meddwl, yn cyfoethogi cymeriad ac yn bwydo’r enaid. A dwi’n gwybod hynny o brofiad gan fy mod i wedi bod yn lwcus o gael cyfleoedd i deithio pedwar ban byd!"
            : "Travel broadens the mind. Literally. Travel expands the mind, enriches your character and feeds the soul. And I know that from experience as I've been lucky enough to have opportunities to travel to the four corners of the world! "}
        </p>
        <p className="resourceP">
          {lang
            ? "Pan gefais fy ngeni yn Llundain yn 1951 gwnaeth fy nhad addewid -  pe byddai unrhyw arian yn sbâr ni fyddai’n gwario ar bethau materol byd, yn hytrach fe fyddai’n gwario ar sicrhau fy mod i’n cael profiadau, profiadau, a mwy o brofiadau! A chwarae teg iddo, mi gadwodd at ei air. Gyda chefnogaeth fy rhieni rwyf wedi gweld a phrofi llawer o’r hen fyd ‘ma dros y blynyddoedd! "
            : "When I was born in London in 1951 my father made a promise. If he had any spare money he wouldn't spend it on material things bur rather spend on ensuring that I would get experiences, experiences and more experiences! And fair play, he kept to his word. With support from my parents I've seen and experienced a lot of this old world over the years.  "}{" "}
        </p>
        <p className="resourceP">
          {lang
            ? "Pan oeddwn i ond yn 9 oed cefais fynd i Nigeria i gael addysg mewn Ysgol Gatholig. Roedd yn brofiad a hanner, anodd ar y dechrau efallai, ond wrth edrych yn nol rhoddodd y cyfnod hwn sylfaen gadarn i mi ar gyfer gweddill fy mywyd. Yn yr ysgol roeddwn wrth fy modd yn canu a dawnsio ac roedd yr ysgol wastad yn cynnig cyfleoedd i mi i ddysgu ieithoedd newydd. Cyfleoedd a arweiniodd i mi allu astudio ieithoedd a chyfathrebu yn y Brifysgol. "
            : "When I was only 9 years old I got to go to Nigeria to get my education in a Catholic School. It was quite an experience, difficult at first, but looking back this period gave me a solid foundation for the rest of my life. In school I loved singing and dancing and I was always given opportunities to learn new languages. These opportunities lead me to study languages and communication in university.   "}
        </p>
        <p className="resourceP">
          {lang
            ? "Wedi i mi raddio o’r Brifysgol dilynais sawl gyrfa amrywiol. Rwyf wedi gweithio i gwmni recordiau CBS yn Llundain a chael cyfarfod enwogion di-ri. Cefais gyfnod o weithio fel cyfieithydd yn y Llysgenhadaeth ar Ynys Grenada. Yn ystod y cyfnod hwn dysgais pa mor bwysig yw hi i roi eich hun yn ‘sgidiau pobl eraill ac edrych ar y byd drwy eu llygaid nhw. Gwelais pa mor fregus yw bywyd i nifer o bobl, pa mor eiddil yw nifer o’n cynefinoedd naturiol ni a sylweddolais pa mor bwysig yw’r berthynas rhwng y ddau beth yma. Ond yn anffodus allai ddim sôn am y llwybr mwyaf dylanwadol yn fy ngyrfa i neu mi fuaswn i’n torri’r Ddeddf Cyfrinachau Swyddogol ac mewn cryn dipyn o helynt dybiwn i. Yr unig beth allai ddatgan yw fy mod wedi cael amser cyffrous iawn yn gweithio i Hughes Aircrafts Systems International ar gyfer NATO! "
            : "After I graduated from university I pursued several varied careers. I've worked for the record company CBS in London and met famous people galore. I had a period of working as a translator for the Embassy in Grenada. During this time I learnt how important it is to put yourself in other people's shoes and look at the world through their eyes. I saw how fragile life is for so many people, how fragile some of our habitats are, and how important the relationship between the two is. Unfortunately I cannot talk about the most important path in my career as I'd be breaking the Official Secrets Act and would be in a lot of trouble. I can say that I had a very exciting time working for Hughes Aircraft Systems International for NATO! "}
        </p>
        <p className="resourceP">
          {lang
            ? "Felly do, rydw i wedi gweld a phrofi llawer o’r hen fyd ‘ma yn ystod fy mywyd, da a drwg. Rwyf wedi gweld gymaint o bethau anhygoel mae pobl yn eu gwneud i helpu ei gilydd ac i helpu’r amgylchedd ond hefyd rwyf wedi profi rhagfarn a hiliaeth ar draws y byd gan gynnwys yma yng Nghymru, fy nghartref i erbyn hyn. "
            : "So I've seen and experienced a lot of this old world during my lifetime, good and bad. I've seen so many incredible things that people do to help each other and the environment. But I've also experienced prejudice and racism across the world including in Wales, my home by now. "}
        </p>
        <p className="resourceP">
          {lang
            ? "Rwy’n teimlo fy mod wedi bod yn lwcus iawn o allu cyfrannu at wneud y byd 'ma’n le gwell ond hefyd rwy’n ymwybodol fod llawer ar ôl i’w wneud os ydym am fyw gyda’n gilydd mewn heddwch ar y blaned hon. "
            : "I feel I've been privelidged and lucky to be able to contribute to making this world a better place but there's a lot more to do if we are to live together in peace on this planet.    "}
        </p>
        <div
          className="learningOutcomes"
          style={{ "background-color": "#FF686B" }}
        >
          <h2>{lang ? "Eich tro chi! " : "Your turn! "}</h2>
          <p className="resourceP">
            {lang
              ? "Yn y darn dychmygol hwn mae Louisa BB Morgan yn edrych yn nol ar ei bywyd a’r effaith y mae’r holl deithio wedi ei gael arni. Beth am i chi ysgrifennu atgofion am daith yr ydych wedi bod arno neu efallai am daith ddychmygol yr hoffech ei wneud yn y dyfodol. Disgrifiwch y lleoliadau yr ydych wedi ymweld â nhw, eich profiadau yno a’r effaith a gafodd yr ymweliad arnoch chi e.e. ar daith i Krakow byddai ymweliad ag Auschwitz yn debygol o adael argraff fawr ar rywun.  "
              : `In this imagined piece Lousia BB Morgan looks back at her life and the effect that all the travelling has had on her. Why don't you write about memories of a journey that you've been on or an imaginary journey that you would like to do in the future. Describe the locations you've visited, your experiences there and the effect that the visit had on you. e.g. if you travelled to Krakow a visit to Auschwitz would be likely to have a big effect on someone.   `}
          </p>
          <p className="resourceP">
            <strong>{lang ? "Deilliant Dysgu:" : "Learning Outcome:"}</strong>
            <br />{" "}
            {lang
              ? "Gallu ysgrifennu hunangofiant"
              : "Able to write a biography"}
          </p>
          <p className="resourceP">
            <strong>
              {lang ? "Meini Prawf Llwyddiant: " : "Success Criteria:"}
            </strong>
            <br />{" "}
            {lang
              ? "Gwneud gwaith ymchwil a chofnodi ffeithiau am y lleoliadau dan sylw "
              : "Do research and record facts about the locations"}
            <br />
            {lang
              ? "Meddwl am y profiadau y gallech fod wedi eu cael yno "
              : "Think about the experiences you had or could have had there "}
            <br />
            {lang
              ? "Disgrifio sut mae’r profiadau hyn yn gwneud i chi deimlo  "
              : "Describe how these experiences made you feel  "}
            <br />
            {lang
              ? "Ysgrifennu yn y person cyntaf ac amser gorffennol y ferf  "
              : "Write in the first person using the past tense of the verb  "}
          </p>
        </div>{" "}
        <div className="backToButton" onClick={() => navigate(-1)}>
          {lang ? "Nol" : "Back"}
        </div>
      </div>
    </>
  );
};

export default LouisaBiog;
