import React from "react";
import "./Info.css";
import { useContext } from "react";
import { LanguageContext } from "../../Helper/Context";

const Info = () => {
  document.body.style = "background: #f2f2f2;";
  const { lang, setLang } = useContext(LanguageContext);

  return (
    <div className="infoMain">
      <h1 className="infoH1">
        {" "}
        {lang ? "Cydnabyddiaethau " : "Acknowledgements"}
      </h1>
      <p className="infoP">
        {lang
          ? "Hoffai Telesgop ddiolch i bawb sydd wedi cyfrannu i'r wefan yn cynnwys -"
          : "  Telesgop would like to thank everyone who contributed to the website including -"}{" "}
      </p>
      <div className="creditsGrid">
        <div className="creditBubble">
          <h1 className="creditsH1">{lang ? "Awduron" : "Authors"}</h1>
          <p>Branwen Davies</p>
          <p>Jade Davies</p>
          <p>Josephine Farag</p>
          <p>Annie Grooms</p>
          <p>Anna-Lisa Jenaer</p>
          <p>Beverly Rogers</p>
          <p>Osian Rowlands</p>
          <p>Wyn Thomas</p>
          <p>Emma Watt</p>
          <p>Jess Wilde</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">
            {" "}
            {lang
              ? "Ymgynghorwyr a Grŵp Monitro"
              : "Advisors & Monitoring Group"}
          </h1>
          <p>Ashok Ahir</p>
          <p>Isaac Blake</p>
          <p>Jade Davies</p>
          <p>Tudur Dylan</p>
          <p>Catrin Gwyn</p>
          <p>Elizabeth Harewood</p>
          <p>Anwen Jones</p>
          <p>Natalie Jones</p>
          <p>Savanna Jones</p>
          <p>Susan Joshi</p>
          <p>Emily Pemberton</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">
            {lang ? "Grŵp Treialu " : "Trialling Group"}
          </h1>
          <p>Manon Jones, Ysgol Gynradd Gymraeg Hamadryad</p>
          <p>Matthew Jones & Rachel Davies, Ysgol Bro Famau</p>
          <p>Huw Price & Owen Morgan, Ysgol Gyfun Gymraeg Plasmawr</p>
          <p>Megan Price, Ysgol Cwm Rhymni</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">{lang ? "Cyfranwyr" : "Contributors"}</h1>
          <p>Theo Cabango</p>
          <p>Hanan Issa</p>
          <p>Mirain Iwerydd</p>
          <p>Dom James</p>
          <p>Gerallt Wyn Jones</p>
          <p>Natalie Jones</p>
          <p>Sian Jones</p>
          <p>Gaynor Legall</p>
          <p>Pete Leung</p>
          <p>Michael Mail</p>
          <p>Mel Owen</p>
          <p>Emily Pemberton</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">
            {lang ? "Cyfieithu a Golygu" : "Translation and Editing"}
          </h1>
          <p>Elin Meek</p>
          <p>Gwenllian Richards</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">Telesgop</h1>
          <p>Srishti Berry</p>
          <p>Leia Cordey</p>
          <p>Mared Dafydd</p>
          <p>Siwan Fflur</p>
          <p>Tom Mann</p>
          <p>Mike Moore</p>
          <p>Teleri Rees</p>
          <p>Aled Richards</p>
          <p>Rhodri Rutherford</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">{lang ? "Arall" : "Other"}</h1>
          <p>Mwnci Animation</p>
          <p>Beca Evans</p>
          <p>Jeia Puri-Evans</p>
          <p>Gafyn Jones</p>
          <p>Guto Orwig</p>
          <p>Twm Richards</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">
            {lang
              ? "Diolch i'r canlynol am ganiatáu i ni ddefnyddio eu lluniau / fideos dan hawlfraint.  "
              : "Thank you to the following for allowing us to use their images / videos under copyright."}
          </h1>
          <p>Dr Ganesh Subrahmanyam</p>
          <p>Sioned Birchall</p>
          <p>Cwmni Da</p>
          <p>Kyle Legall</p>
          <p>Martha Stone Productions</p>
          <p>Race Council Cymru</p>
          <p>People's Collection of Wales</p>
          <p>Sidoli's Ice Cream</p>
          <p>Jonathan Lichtenstein</p>
        </div>
        <div className="creditBubble">
          <h1 className="creditsH1">
            {lang ? " " : "Creative Commons Attributions"}
          </h1>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Kizzy_Crawford.jpg">
              Rhys Thomas/Ochr 1/Antena
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by-sa/4.0">
              CC BY-SA 4.0
            </a>
            , via Wikimedia Commons
          </p>
          <p>
            Chris Andrews&nbsp;/&nbsp;
            <i>The Top of Church Street, Merthyr Tydfil</i>
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Arandora_Star_1940.jpg">
              Royal navy
            </a>
            , Public domain, via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:JoeCalzaghe-July2007.jpg">
              Ben Duffy
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>,
            via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:TV-opnamen_van_Engelse_zangeres_Shirley_Bassey_Shirley_Bassey_,_kop,_Bestanddeelnr_924-1644.jpg">
              Rob Mieremet / Anefo
            </a>
            , CC0, via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Penrhyn_Castle_Wales_015.jpg">
              Bs0u10e01
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by-sa/4.0">
              CC BY-SA 4.0
            </a>
            , via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Penrhyn_Castle_-_geograph.org.uk_-_3718225.jpg">
              Penrhyn Castle by Ian Capper
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by-sa/2.0">
              CC BY-SA 2.0
            </a>
            , via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:2012-03-19_Jason_Mohammad_at_Wales_Grand_Slam_Celebrations.jpg">
              Andrew Hazard for National Assembly for Wales
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>,
            via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Jason_Mohammad_(29817197060).jpg">
              Senedd Cymru / Welsh Parliament from Wales
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>,
            via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Vaughan_Gething.jpg">
              National Assembly For Wales / Cynulliad Cymru profile
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by/2.0">CC BY 2.0</a>,
            via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:%22Paul_Robeson,_world_famous_Negro_baritone,_leading_Moore_Shipyard_(Oakland,_CA)_workers_in_singing_the_Star_Spangled_Ba_-_NARA_-_535874.jpg">
              National Archives at College Park
            </a>
            , Public domain, via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:AUT_vs._WAL_2016-10-06_(130).jpg">
              Steindy (talk) 16:12, 10 November 2016 (UTC)
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by-sa/3.0">
              CC BY-SA 3.0
            </a>
            , via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:AUT_vs._WAL_2016-10-06_(127).jpg">
              Steindy (talk) 16:12, 10 November 2016 (UTC)
            </a>
            ,{" "}
            <a href="https://creativecommons.org/licenses/by-sa/3.0">
              CC BY-SA 3.0
            </a>
            , via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Shirley_Bassey_Wembley_2006_(cropped).jpg">
              Nyctc7
            </a>
            , Public domain, via Wikimedia Commons
          </p>
          <p>
            <a href="https://commons.wikimedia.org/wiki/File:Portrait_of_John_Ystumllyn,_1754_(4671107)_(cropped).jpg">
              National Library of Wales
            </a>
            , Public domain, via Wikimedia Commons
          </p>
        </div>
      </div>
      <p className="infoP">
        {lang
          ? "Rydym wedi gwneud pob ymdrech i sicrhau lluniau sy’n rhydd o hawlfraint o Creative Commons a safleoedd eraill. Os ydych yn credu bod unrhyw lun yn torri deddf hawlfraint yna cysylltwch â ni."
          : "Efforts have been made to source copyright-free images via Creative Commons and other sites. However if it is believed any image contravenes copyright please let us know. "}{" "}
      </p>
      <p className="infoP">Site version 1.0.1 </p>
    </div>
  );
};

export default Info;
